<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h4 class="p-mt-1">Customer Infomation</h4>
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <Toolbar>
                    <template #start>
                        <Button icon="pi pi-plus" label="Add Customer" class="p-button-success mr-2" @click="openNew()" />
                        <InputText type="text" placeholder="Username" class="mr-2" v-model="state.nameS" />
                    </template>

                    <template #end>
                        <Button icon="pi pi-search" label="Search" class="p-button-info mr-2" @click="getData()" />
                        <Button icon="pi pi-refresh" label="Reset" class="p-button-warning" @click="reset()" />
                    </template>
                </Toolbar>
                <DataTable
                    :autoLayout="true"
                    :value="state.customers"
                    :lazy="true"
                    stripedRows
                    :paginator="true"
                    :rows="50"
                    :totalRecords="state.totalRecords"
                    :loading="state.loading"
                    :rowsPerPageOptions="[50, 100, 200]"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    dataKey="id"
                    class="p-datatable-sm"
                >
                    <Column field="no" :header="$t('common.number')" :style="{ width: '100px', textAlign: 'center' }" headerClass="center">
                        <template #body="slotProps">
                            {{ slotProps.index + 1 }}
                        </template>
                    </Column>
                    <Column field="username" header="Username" sortable> </Column>
                    <Column field="secretkey" header="Secret Key"></Column>
                    <Column field="whiteList" header="WhiteList"></Column>
                    <Column field="address" header="Address"></Column>
                    <Column field="telephone" header="Telephone"></Column>
                    <Column field="createdOn" header="Create Dt" :style="{ textAlign: 'center' }" headerClass="center" sortable>
                        <template #body="{ data }">
                            {{ useDateFormat(data.createdOn, 'YYYY-MM-DD HH:mm').value }}
                        </template>
                    </Column>
                    <Column headerStyle="width: 8rem" headerClass="p-text-center" bodyClass="p-text-center">
                        <template #body="slotProps">
                            <Button icon="pi pi-check-square" class="p-button-rounded p-button-success m-1" @click="openEdit(slotProps.data)" />
                            <Button icon="pi pi-book" class="p-button-rounded m-1" @click="openCinfoEdit(slotProps.data)" />
                        </template>
                    </Column>
                    <template #footer> In total there are {{ formatInt(state.totalRecords) }} item. </template>
                </DataTable>
                <Dialog v-model:visible="state.dialog" header="User Customer" :modal="true" :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '50vw' }">
                    <Message v-if="state.errorMessage" severity="error">{{ state.errorMessage }}</Message>
                    <div class="p-fluid">
                        <div class="field">
                            <label>Username *</label>
                            <InputText v-model="state.form.username" />
                        </div>
                        <div class="field">
                            <label>Secret Key *</label>
                            <InputText v-model="state.form.secretkey" />
                        </div>
                        <div class="field">
                            <label>URL Callback</label>
                            <InputText v-model="state.form.urlCallback" />
                        </div>
                        <div class="field">
                            <label>WhiteList</label>
                            <InputText v-model="state.form.whiteList" />
                        </div>
                    </div>
                    <template #footer>
                        <Button label="Save" icon="pi pi-check" class="p-button-success" @click="saveUserCustomer" autofocus></Button>
                        <Button label="Cancel" icon="pi pi-times" class="p-button-danger" @click="close()"></Button>
                    </template>
                </Dialog>
                <Dialog v-model:visible="state.dialogInfo" header="User Customer Infomation" :modal="true" :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '50vw' }">
                    <Message v-if="state.errorMessage" severity="error">{{ state.errorMessage }}</Message>
                    <div class="p-fluid">
                        <div class="field">
                            <label>ชื่อ - นามสกุล</label>
                            <InputText v-model="state.cinfo.cname" />
                        </div>
                        <div class="field">
                            <label>เบอร์โทรศัพท์</label>
                            <InputMask v-model="state.cinfo.telephone" mask="(999) 999-9999" placeholder="(999) 999-9999" />
                        </div>
                        <div class="field">
                            <label>ชื่อบริษัทห้างร้าน</label>
                            <InputText v-model="state.cinfo.companyName" />
                        </div>
                        <div class="field">
                            <label>ที่อยู่</label>
                            <InputText v-model="state.cinfo.address" />
                        </div>
                        <div class="field">
                            <label>อีเมล</label>
                            <InputText v-model="state.cinfo.cemail" placeholder="xxxx@mail.com" />
                        </div>
                        <div class="field">
                            <label>URL เอกสาร</label>
                            <InputText v-model="state.cinfo.cdoc" placeholder="http://google.com/doc" />
                        </div>
                    </div>
                    <template #footer>
                        <Button label="Save" icon="pi pi-check" class="p-button-success" @click="saveUserCustomerInfo" autofocus></Button>
                        <Button label="Cancel" icon="pi pi-times" class="p-button-danger" @click="state.dialogInfo = false"></Button>
                    </template>
                </Dialog>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, reactive } from 'vue';
import { useToast } from 'primevue/usetoast';
// import { useConfirm } from 'primevue/useconfirm';
// import { useAuthStore } from '@/store/auth';
import { useAuthStore } from '@/store/auth';
import UserCustomerService from '@/service/UserCustomerService';
import { useRouter } from 'vue-router';
import { formatInt } from '@/common/Utils';
import { useDateFormat } from '@vueuse/shared';
import { useI18n } from 'vue-i18n';

const authUserStore = useAuthStore();
const router = useRouter();
// const confirm = useConfirm();
const toast = useToast();
const { t } = useI18n();
const auth = computed(() => authUserStore.user);

const state = reactive({
    errorMessage: '',
    loading: false,
    dialog: false,
    dialogInfo: false,
    nameS: '',
    customers: [],
    totalRecords: 0,
    form: {
        mode: '',
        id: '',
        username: '',
        secretkey: '',
        whiteList: '',
        urlCallback: '',
        creator: '',
        updated: '',
    },
    cinfo: {
        mode: '',
        id: '',
        address: '',
        telephone: '',
        cname: '',
        companyName: '',
        cemail: '',
        cdoc: '',
        updated: '',
    },
});

const getData = async () => {
    try {
        state.loading = true;
        const response = await UserCustomerService.findUserCustomerList(state.nameS, 0, 100);
        state.customers = response.data.data;
        state.totalRecords = response.data.total;
    } catch (error) {
        if (error.response.status === 403) {
            router.push({ name: 'Login' });
        }
        console.log(JSON.stringify(error));
        state.errorMessage = t('0999', [error.message]);
    } finally {
        // state.isLoading = false;
        state.loading = false;
    }
};

const openNew = async () => {
    try {
        state.errorMessage = '';
        state.form.mode = 'A';
        state.form.id = '';
        state.form.username = '';
        state.form.secretkey = '';
        state.form.whiteList = '';
        state.form.urlCallback = '';
        state.dialog = true;
    } catch (error) {
        console.log(JSON.stringify(error));
        state.errorMessage = t('0999', [error.message]);
    } finally {
        state.loading = false;
    }
};

const openEdit = async (customer) => {
    try {
        state.errorMessage = '';
        state.form.mode = 'U';
        state.form.id = customer.id;
        state.form.username = customer.username;
        state.form.secretkey = customer.secretkey;
        state.form.whiteList = customer.whiteList;
        state.form.urlCallback = customer.urlCallback;
        state.dialog = true;
    } catch (error) {
        // console.log(JSON.stringify(error));
        state.errorMessage = t('0999', [error.message]);
    } finally {
        state.loading = false;
    }
};

const openCinfoEdit = async (customer) => {
    try {
        state.errorMessage = '';
        state.cinfo.mode = 'U';
        state.cinfo.id = customer.id;
        state.cinfo.address = customer.address;
        state.cinfo.telephone = customer.telephone;
        state.cinfo.cname = customer.cname;
        state.cinfo.companyName = customer.companyName;
        state.cinfo.cemail = customer.cemail;
        state.cinfo.cdoc = customer.cdoc;
        state.dialogInfo = true;
    } catch (error) {
        // console.log(JSON.stringify(error));
        state.errorMessage = t('0999', [error.message]);
    } finally {
        state.loading = false;
    }
};

const saveUserCustomer = async () => {
    try {
        if (state.form.username == null || state.form.username == '') {
            state.errorMessage = 'Username';
            return;
        }
        if (state.form.secretkey == null || state.form.secretkey == '') {
            state.errorMessage = 'Secret Key';
            return;
        }
        if (state.form.mode == 'A') {
            state.form.creator = auth.value.username;
            state.form.updated = auth.value.username;
            const { data: response } = await UserCustomerService.saveUserCustomerNew(state.form);
            //console.log(response);
            if (response.status === 'OK') {
                toast.add({ severity: 'info', summary: t('common.process'), detail: t('common.complete.add'), life: 3000 });
                getData();
                state.dialog = false;
            } else {
                toast.add({ severity: 'error', summary: t('common.process'), detail: t(response.code), life: 3000 });
            }
        } else {
            state.form.updated = auth.value.username;
            const { data: response } = await UserCustomerService.saveUserCustomerUpdate(state.form);
            //console.log(response);
            if (response.status === 'OK') {
                toast.add({ severity: 'info', summary: t('common.process'), detail: t('common.complete.update'), life: 3000 });
                getData();
                state.dialog = false;
            } else {
                toast.add({ severity: 'error', summary: t('common.process'), detail: t(response.code), life: 3000 });
            }
        }
    } catch (error) {
        // console.log(error);
        state.errorMessage = t('0999', [error.message]);
    }
};

const saveUserCustomerInfo = async () => {
    try {
        state.cinfo.updated = auth.value.username;
        const { data: response } = await UserCustomerService.saveUserCustomerInfoUpdate(state.cinfo);
        //console.log(response);
        if (response.status === 'OK') {
            toast.add({ severity: 'info', summary: t('common.process'), detail: t('common.complete.update'), life: 3000 });
            getData();
            state.dialogInfo = false;
        } else {
            toast.add({ severity: 'error', summary: t('common.process'), detail: t(response.code), life: 3000 });
        }
    } catch (error) {
        // console.log(error);
        state.errorMessage = t('0999', [error.message]);
    }
};

const close = async () => {
    state.dialog = false;
};

const reset = async () => {
    state.nameS = '';
    getData();
};

onMounted(() => {
    getData();
});
</script>
<style lang="scss">
.center {
    .p-column-header-content {
        text-align: center;
        display: block !important;
    }
}

.right {
    .p-column-header-content {
        text-align: right;
        display: block !important;
    }
}
</style>
